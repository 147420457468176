import React, { useEffect, useState, useRef } from 'react';
import Header from '../components/Headers/Header';
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Container,
  Col,
  InputGroup,
  Row,
} from 'reactstrap';
import useViewport from '../hooks/useViewport';
import Flatpickr from 'react-flatpickr';
import SearchComponent from '../components/Search/Search';
import ReactTable from './ReactTable';
import { format } from 'date-fns';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDevotionalThunkAction } from '../redux/devotional/action';
import { fetchDashboardThunkAction } from '../redux/dashboard/action';
import { selectDashboardList } from '../redux/dashboard/selectors';
import { selectDevotionalList } from '../redux/devotional/selectors';
import devotionalCount from '../assets/img/brand/devotional-count.png';
import registeredUsers from '../assets/img/brand/registered-users.png';
import uniqueViews from '../assets/img/brand/unique-views.png';
import Views from '../assets/img/brand/views.png';

function DashboardPage() {
  const { width } = useViewport();
  const [picker, setPicker] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [error, setError] = React.useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchDashboardThunkAction({ startDate, endDate }, onSuccess, onError)
    );
  }, []);

  const { dashboardList } = useSelector(selectDashboardList);

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  const handleDateRange = (date) => {
    const startDate = moment(date[0]).format('YYYY-MM-DDT00:00:00');
    const endDate = moment(date[1]).format('YYYY-MM-DDT23:59:00');

    setStartDate(startDate);
    setEndDate(endDate);
  };

  const onFilter = () => {
    if (startDate !== null && endDate !== null) {
      dispatch(
        fetchDevotionalThunkAction({ startDate, endDate }, onSuccess, onError)
      );
    }

    dispatch(
      fetchDashboardThunkAction({ startDate, endDate }, onSuccess, onError)
    );
  };

  const refComp = useRef(null);

  const clearDate = () => {
    refComp.current.flatpickr.clear();
    setStartDate(null);
    setEndDate(null);
    if (startDate && endDate) {
      dispatch(fetchDevotionalThunkAction({ limit: 10 }, onSuccess, onError));
    }
    dispatch(fetchDashboardThunkAction(onSuccess, onError));
  };

  return (
    <>
      <Header />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="shadow" style={{ minHeight: '50vh' }}>
              <CardHeader
                className={
                  width < 768
                    ? 'border-0'
                    : 'border-0 d-flex justify-content-between'
                }
              >
                <div
                  style={
                    width < 768
                      ? {
                          display: 'flex',
                          justifyContent: 'space-between',
                          marginBottom: '10px',
                        }
                      : {}
                  }
                >
                  <h3 className="mb-0">Dashboard</h3>
                </div>
                <div>
                  <Col>
                    <InputGroup>
                      <Flatpickr
                        value={picker}
                        className="col-sm-12 form-control flatpicker-filter"
                        onChange={handleDateRange}
                        ref={refComp}
                        placeholder="Select Date Range"
                        options={{
                          mode: 'range',
                          dateFormat: 'm/d/y',
                          maxDate: new Date(),

                          onClose: function (selectedDates, dateStr, instance) {
                            if (selectedDates.length == 1) {
                              instance.setDate(
                                [selectedDates[0], selectedDates[0]],
                                true
                              );
                            }
                          },
                        }}
                      />
                      <Button size="md" color="primary" onClick={onFilter}>
                        Filter
                      </Button>
                      <Button size="md" color="primary" onClick={clearDate}>
                        Clear
                      </Button>
                    </InputGroup>
                  </Col>
                </div>
              </CardHeader>

              <CardBody className="p-3">
                <Row>
                  <div className="col-lg-6 col-xl-3">
                    <div
                      className="card-stats mb-4 mb-xl-0 card"
                      style={{
                        backgroundColor: '#e1f0dd',
                        borderRadius: '10px',
                        height: 'auto',
                      }}
                    >
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <h5 className="text-uppercase text-muted mb-0 card-title registered">
                              Registered <br /> Users
                            </h5>
                            <span className="h2 font-weight-bold mb-0">
                              {dashboardList?.userCounts}
                            </span>
                          </div>
                          <div className="col-auto col">
                            <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                              <img
                                src={registeredUsers}
                                style={{ width: '100%' }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-xl-3">
                    <div
                      className="card-stats mb-4 mb-xl-0 card"
                      style={{
                        backgroundColor: '#e1f0dd',
                        borderRadius: '10px',
                        height: 'auto',
                      }}
                    >
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <h5 className="text-uppercase text-muted mb-0 card-title registered">
                              Devotional <br /> Count
                            </h5>
                            <span className="h2 font-weight-bold mb-0">
                              {dashboardList?.devotionalsCounts}
                            </span>
                          </div>
                          <div className="col-auto col">
                            <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                              <img
                                src={devotionalCount}
                                style={{ width: '100%' }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-xl-3">
                    <div
                      className="card-stats mb-4 mb-xl-0 card"
                      style={{
                        backgroundColor: '#e1f0dd',
                        borderRadius: '10px',
                        height: 'auto',
                      }}
                    >
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <h5 className="text-uppercase text-muted mb-0 card-title registered">
                              Total <br /> Views
                            </h5>
                            <span className="h2 font-weight-bold mb-0">
                              {dashboardList?.totalViewCounts}
                            </span>
                          </div>
                          <div className="col-auto col">
                            <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                              <img src={Views} style={{ width: '100%' }} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-xl-3">
                    <div
                      className="card-stats mb-4 mb-xl-0 card"
                      style={{
                        backgroundColor: '#e1f0dd',
                        borderRadius: '10px',
                        height: 'auto',
                      }}
                    >
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <h5 className="text-uppercase text-muted mb-0 card-title registered">
                              Unique <br /> Views
                            </h5>
                            <span className="h2 font-weight-bold mb-0">
                              {dashboardList?.uniqueViewCounts}
                            </span>
                          </div>
                          <div className="col-auto col">
                            <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                              <img
                                src={uniqueViews}
                                style={{ width: '100%' }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Row>

                <ReactTable />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default DashboardPage;
