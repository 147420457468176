import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Table, Spinner, Label } from 'reactstrap';
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from 'react-table';
import { header } from '../Data/UnassignedDevotionalData';
import UnassignedDevotionalModal from './UnassignedDevotionalModal';
import {
  getUnassignedDevotionalThunkAction,
  fetchDevotionalThunkAction,
  updateDevotionalThunkAction,
  deleteDevotionalThunkAction,
  getDevotionalConfigThunkAction,
} from '../redux/devotional/action';
import { useSelector, useDispatch } from 'react-redux';
import '../App.css';
import { selectDevotionalList } from '../redux/devotional/selectors';
import moment from 'moment';
import Swal from 'sweetalert2';

const DEFAULT_DATA = {
  id: '',
  title: '',
  description: '',
  postedOn: '',
};

const UnassignedReactTable = () => {
  const [error, setError] = React.useState(null);
  const [unassignedDevotional, setUnassignedDevotional] =
    useState(DEFAULT_DATA);
  const [unassignedModal, setUnassignedModal] = useState(false);
  const dispatch = useDispatch();
  const { unassignedDevotionalList, isLoading, devotionalList } =
    useSelector(selectDevotionalList);
  const [unassignedSelectingDate, setUnassignedSelectingDate] = useState('');

  useEffect(() => {
    dispatch(fetchDevotionalThunkAction(onSuccess, onError));
    dispatch(getUnassignedDevotionalThunkAction(onSuccess, onError));
  }, []);

  const localDate = moment(devotionalList[0]?.postedOn).format('MM-DD-YYYY');
  const localDatMoment = moment(localDate, 'MM-DD-YYYY');

  const devotionalDiffMoment = moment(
    moment.utc(devotionalList[0]?.postedOn).format('MM-DD-YYYY'),
    'MM-DD-YYYY'
  );

  const result = localDatMoment.diff(devotionalDiffMoment, 'days');

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    // setError(error);
  };

  const columns = useMemo(() => header, []);
  const data = useMemo(
    () => unassignedDevotionalList,
    [unassignedDevotionalList]
  );

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination,
    useRowSelect
  );

  const toggleUnassigned = (unassignedDevotional) => {
    setUnassignedDevotional(unassignedDevotional);
    setUnassignedModal((prev) => !prev);
    setUnassignedSelectingDate('');
  };

  const unassignedUpdateSuccess = () => {
    dispatch(getUnassignedDevotionalThunkAction(onSuccess, onError));
    dispatch(fetchDevotionalThunkAction(onSuccess, onError));
    toggleUnassigned((prev) => !prev);
  };

  const deleteSuccess = () => {
    dispatch(fetchDevotionalThunkAction(onSuccess, onError));
    dispatch(getUnassignedDevotionalThunkAction(onSuccess, onError));
    dispatch(getDevotionalConfigThunkAction(onSuccess, onError));
    toggleUnassigned((prev) => !prev);
  };

  const handleUnassignedDevotionalChange = (key, event) => {
    const setDate = moment(new Date(event)).format('MM-DD-YYYY');

    setUnassignedDevotional((prev) => ({
      ...prev,
      [key]: (key === 'date' && setDate) || event.target.value,
    }));
    if (key === 'date') {
      setUnassignedSelectingDate(setDate);
    }
  };

  const handleUnassignedEdit = async () => {
    const formatUnassignedEditingDate = unassignedSelectingDate
      ? moment(unassignedSelectingDate, 'MM-DD-YYYY').format(
          'YYYY-MM-DDT00:00:00'
        )
      : null;

    const setSetectingDateToUtcAdd = unassignedSelectingDate
      ? moment(formatUnassignedEditingDate)
          .add(Math.abs(result), 'days')
          .format('YYYY-MM-DDT00:00:00')
      : null;

    const setSetectingDateToUtcSub = unassignedSelectingDate
      ? moment(formatUnassignedEditingDate)
          .subtract(Math.abs(result), 'days')
          .format('YYYY-MM-DDT00:00:00')
      : null;

    dispatch(
      updateDevotionalThunkAction(
        unassignedDevotional?._id,
        {
          title: unassignedDevotional?.title,
          description: unassignedDevotional?.description,
          postedOn:
            result < 0 ? setSetectingDateToUtcAdd : setSetectingDateToUtcSub,
        },
        unassignedUpdateSuccess,
        onError
      )
    );
  };

  const handleDelete = async () => {
    const popupResponse = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      background:
        localStorage.getItem('skin') === '"' + 'dark' + '"'
          ? '#161D31'
          : '#F8F8F8',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ms-1',
      },
      buttonsStyling: false,
    });
    if (popupResponse.isConfirmed) {
      dispatch(
        deleteDevotionalThunkAction(
          unassignedDevotional?._id,
          deleteSuccess,
          onError
        )
      );
    }
  };
  return (
    <>
      {isLoading ? (
        <Fragment>
          <div className="post_error_loading">
            <Spinner />
          </div>
        </Fragment>
      ) : error ? (
        <Fragment>
          <div className="error">{error}</div>
        </Fragment>
      ) : (
        <>
          {unassignedDevotionalList.length !== 0 ? (
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th>Title</th>
                  <th>Unassigned Devotional Details</th>
                </tr>
              </thead>
              <tbody>
                {unassignedDevotionalList?.map((item) => (
                  <tr>
                    <td>{item.title}</td>
                    <td>
                      <i
                        className="fa fa-eye cursor-pointer"
                        style={{
                          color: '#5A6B5D',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={() => toggleUnassigned(item)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div colSpan="5" style={{ textAlign: 'center', fontSize: '130%' }}>
              <Label className="small">No Unassigned Devotionals.</Label>
            </div>
          )}

          <UnassignedDevotionalModal
            toggleUnassigned={toggleUnassigned}
            unassignedModal={unassignedModal}
            handleUnassignedDevotionalChange={handleUnassignedDevotionalChange}
            unassignedDevotional={unassignedDevotional}
            unassignedSelectingDate={unassignedSelectingDate}
            handleUnassignedEdit={handleUnassignedEdit}
            handleDelete={handleDelete}
          />
        </>
      )}
    </>
  );
};

export default UnassignedReactTable;
