import DevotionalPage from './pages/DevotionalPage';
import DashboardPage from './pages/DashboardPage';
import UnassignedDevotional from './pages/UnassignedDevotional';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import Icon from '@mui/material/Icon';

export const adminRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'ni ni-single-copy-04 text-main-color',
    component: DashboardPage,
    isRouteOnly: false,
    layout: '/admin',
  },
  {
    path: '/devotional',
    name: 'Devotional',
    icon: 'ni ni-single-02 text-main-color',
    component: DevotionalPage,
    isRouteOnly: false,
    layout: '/admin',
  },
  {
    path: '/unassigned_devotional',
    name: 'Unassigned Devotional',
    icon: 'fa fa-user-times text-main-color',
    component: UnassignedDevotional,
    isRouteOnly: false,
    layout: '/admin',
  },
  //   {
  //     path: '/assessments',
  //     name: 'Assessments',
  //     icon: 'ni ni-single-copy-04 text-main-color',
  //     component: TestsPage,
  //     isRouteOnly: false,
  //     layout: '/admin',
  //   },

  //   {
  //     path: '/subjects/:id',
  //     name: 'PatientsList',
  //     icon: 'ni ni-bullet-list-67 text-main-color',
  //     component: PatientsListAdminPage,
  //     isRouteOnly: true,
  //     layout: '/admin',
  //   },
  //   {
  //     path: '/assessment/update/:id',
  //     name: 'Tests',
  //     icon: 'ni ni-bullet-list-67 text-main-color',
  //     component: NewTestPage,
  //     isRouteOnly: true,
  //     layout: '/admin',
  //   },
  //   {
  //     path: '/assessment/add/questionTypes',
  //     name: 'Tests',
  //     icon: 'ni ni-bullet-list-67 text-main-color',
  //     component: NewTestPage,
  //     isRouteOnly: true,
  //     layout: '/admin',
  //   },
  //   {
  //     path: '/assessment/add/questions',
  //     name: 'Tests',
  //     icon: 'ni ni-bullet-list-67 text-main-color',
  //     component: TestMultiStepForm,
  //     isRouteOnly: true,
  //     layout: '/admin',
  //   },
  //   {
  //     path: '/assessment/updatequestions/:id',
  //     name: 'Tests',
  //     icon: 'ni ni-bullet-list-67 text-main-color',
  //     component: TestMultiStepForm,
  //     isRouteOnly: true,
  //     layout: '/admin',
  //   },
  //   {
  //     path: '/subject-details/:id',
  //     name: 'Patients',
  //     icon: 'ni ni-bullet-list-67 text-main-color',
  //     component: PatientDetailsPage,
  //     isRouteOnly: true,
  //     layout: '/admin',
  //   },
  //   {
  //     path: '/profile',
  //     name: 'Profile',
  //     icon: 'ni ni-single-copy-04 text-main-color',
  //     component: ProfilePage,
  //     isRouteOnly: true,
  //     layout: '/admin',
  //   },
  //   {
  //     path: '/view/test/assessment/:assessmentId/assignment/:assignmentId/patient/:patientId',
  //     name: 'Profile',
  //     icon: 'ni ni-single-copy-04 text-main-color',
  //     component: TestScorePage,
  //     isRouteOnly: true,
  //     layout: '/admin',
  //   },
];

// export const psychologistRoutes = [
//   {
//     path: '/subjects',
//     name: 'Subjects',
//     icon: 'ni ni-single-02 text-main-color',
//     component: PatientsPage,
//     isRouteOnly: false,
//     layout: '/admin',
//   },
//   {
//     path: '/subject/add',
//     name: 'Patients',
//     icon: 'ni ni-bullet-list-67 text-main-color',
//     component: NewPatientPage,
//     isRouteOnly: true,
//     layout: '/admin',
//   },
//   {
//     path: '/subject-details/:id',
//     name: 'Patients',
//     icon: 'ni ni-bullet-list-67 text-main-color',
//     component: PatientDetailsPage,
//     isRouteOnly: true,
//     layout: '/admin',
//   },
//   {
//     path: '/subject/update/:id',
//     name: 'Patients',
//     icon: 'ni ni-bullet-list-67 text-main-color',
//     component: NewPatientPage,
//     isRouteOnly: true,
//     layout: '/admin',
//   },
//   {
//     path: '/assessments',
//     name: 'Assessments',
//     icon: 'ni ni-single-copy-04 text-main-color',
//     component: TestsPage,
//     isRouteOnly: false,
//     layout: '/admin',
//   },
//   {
//     path: '/start/test/assessment/:assessmentId/assignment/:assignmentId/patient/:patientId',
//     name: 'Tests',
//     icon: 'ni ni-bullet-list-67 text-main-color',
//     component: GiveTestPage,
//     isRouteOnly: true,
//     layout: '/admin',
//   },
//   {
//     path: '/update/test/assessment/:assessmentId/assignment/:assignmentId/patient/:patientId',
//     name: 'Tests',
//     icon: 'ni ni-bullet-list-67 text-main-color',
//     component: GiveTestPage,
//     isRouteOnly: true,
//     layout: '/admin',
//   },
//   {
//     path: '/score/test/assessment/:assessmentId/assignment/:assignmentId/patient/:patientId',
//     name: 'Tests',
//     icon: 'ni ni-bullet-list-67 text-main-color',
//     component: TestScorePage,
//     isRouteOnly: true,
//     layout: '/admin',
//   },
//   {
//     path: '/score/update/test/assessment/:assessmentId/assignment/:assignmentId/patient/:patientId',
//     name: 'Tests',
//     icon: 'ni ni-bullet-list-67 text-main-color',
//     component: TestScorePage,
//     isRouteOnly: true,
//     layout: '/admin',
//   },
//   {
//     path: '/view/test/assessment/:assessmentId/assignment/:assignmentId/patient/:patientId',
//     name: 'Profile',
//     icon: 'ni ni-single-copy-04 text-main-color',
//     component: TestScorePage,
//     isRouteOnly: true,
//     layout: '/admin',
//   },
//   {
//     path: '/profile',
//     name: 'Profile',
//     icon: 'ni ni-single-copy-04 text-main-color',
//     component: ProfilePage,
//     isRouteOnly: true,
//     layout: '/admin',
//   },
// ];
