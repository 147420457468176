import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  ModalFooter,
  Row,
  Input,
} from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import { selectDevotionalList } from '../redux/devotional/selectors';

function UnassignedDevotionalModal({
  unassignedModal,
  handleUnassignedDevotionalChange,
  unassignedDevotional,
  unassignedSelectingDate,
  handleDelete,
  handleUnassignedEdit,
  toggleUnassigned,
}) {
  const { updateLoading } = useSelector(selectDevotionalList);

  return (
    <>
      <Modal
        isOpen={unassignedModal}
        toggle={toggleUnassigned}
        className="modal-lg"
      >
        <ModalHeader toggle={toggleUnassigned}>
          <Label style={{ fontSize: '20px' }}>Unassigned Devotional</Label>
        </ModalHeader>
        <ModalBody>
          <div className="mb-2">
            <Label className="form-label" for="title">
              Title:
            </Label>
            <Input
              type="text"
              required
              placeholder="Title"
              value={unassignedDevotional?.title}
              onChange={(e) => handleUnassignedDevotionalChange('title', e)}
            />
          </div>
          <div className="mb-2">
            <Label className="form-label" for="description">
              Devotional:
            </Label>
            <Input
              type="textarea"
              placeholder="Devotional"
              rows="7"
              required
              value={unassignedDevotional?.description}
              onChange={(e) =>
                handleUnassignedDevotionalChange('description', e)
              }
            />
          </div>
          <div className="mb-2">
            <Label className="form-label" for="description">
              Date:
            </Label>

            <Flatpickr
              className="form-control"
              options={{
                dateFormat: 'm/d/Y',
                minDate: new Date().fp_incr(2),
              }}
              value={unassignedSelectingDate}
              onChange={(e) => handleUnassignedDevotionalChange('date', e)}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={handleUnassignedEdit}
            disabled={updateLoading ? true : false}
          >
            {updateLoading ? 'Updating...' : 'Update'}
          </Button>
          <Button color="danger" onClick={handleDelete}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default UnassignedDevotionalModal;
