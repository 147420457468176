import moment from 'moment';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  ModalFooter,
  Row,
  Input,
} from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import { selectDevotionalList } from '../redux/devotional/selectors';
import { Troubleshoot } from '@mui/icons-material';

function EditDevotionalModal({
  editModal,
  handleEditChange,
  editData,
  handleEdit,
  editSelectingDate,
  toggleEdit,
  handleDelete,
}) {
  const { updateLoading } = useSelector(selectDevotionalList);

  const a = new Date();
  var d = editSelectingDate;
  var b = new Date();
  b.setMonth(parseInt(d.substr(0, 2)) - 1);
  b.setDate(parseInt(d.substr(3, 2)));
  b.setFullYear(parseInt(d.substr(6, 4)));

  let today = b.getDate();

  const diffTime = b - a;
  const result = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) > 1;

  return (
    <>
      <Modal isOpen={editModal} toggle={toggleEdit} className="modal-lg">
        <ModalHeader toggle={toggleEdit}>
          {result ? (
            <Label style={{ fontSize: '20px' }}>Edit Devotional</Label>
          ) : (
            <Label style={{ fontSize: '20px' }}>View Devotional</Label>
          )}
        </ModalHeader>
        <ModalBody>
          <div className="mb-2">
            <Label className="form-label" for="title">
              Title:
            </Label>
            <Input
              type="text"
              required
              placeholder="Title"
              disabled={result ? false : true}
              value={editData.title}
              onChange={(e) => handleEditChange('title', e)}
            />
          </div>
          <div className="mb-2">
            <Label className="form-label" for="description">
              Devotional:
            </Label>
            <Input
              type="textarea"
              placeholder="Devotional"
              disabled={result ? false : true}
              rows="7"
              required
              value={editData.description}
              onChange={(e) => handleEditChange('description', e)}
            />
          </div>
          <div className="mb-2">
            <Label className="form-label" for="description">
              Date:
            </Label>

            <Flatpickr
              className="form-control"
              options={{
                dateFormat: 'm/d/Y',
                minDate: result ? new Date().fp_incr(2) : editSelectingDate,
              }}
              disabled={result ? false : true}
              value={editSelectingDate}
              onChange={(e) => handleEditChange('date', e)}
            />
          </div>
        </ModalBody>

        {(result && (
          <>
            <ModalFooter>
              <Button
                color="primary"
                onClick={handleEdit}
                disabled={updateLoading ? true : false}
              >
                {updateLoading ? 'Updating...' : 'Update'}
              </Button>
              <Button color="danger" onClick={handleDelete}>
                Delete
              </Button>
            </ModalFooter>
          </>
        )) ||
          null}
      </Modal>
    </>
  );
}

export default EditDevotionalModal;
