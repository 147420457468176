import React from 'react';
import { useLocation, Route, Switch, Redirect } from 'react-router-dom';

import AdminNavbar from '../components/Navbars/AdminNavbar.jsx';
import Sidebar from '../components/Sidebar/Sidebar.jsx';
import { adminRoutes } from '../routes';
import DevotionalPage from '../pages/DevotionalPage.jsx';

import Error from '../pages/Error';

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
    document.body.classList.add('white');
    document.body.style.backgroundColor = '#fff';
    return () => {
      document.body.classList.remove('white');
    };
  }, [location]);

  const getRoutes = (adminRoutes) => {
    return adminRoutes.map((prop, key) => {
      if (prop.layout === '/admin') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <Sidebar
        {...props}
        routes={adminRoutes}
        logo={{
          innerLink: '/admin/list',
          // eslint-disable-next-line no-undef
          imgSrc: require('../assets/img/brand/logo.png').default,
          imgAlt: '...',
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar {...props} />

        <Switch>
          {getRoutes(adminRoutes)}

          <Route path="/admin/resetpassword" exact />
          <>
            <Route path="/admin/list" exact component={DevotionalPage}>
              <Redirect to="/admin/devotional" />
            </Route>
            <Route>
              <Error
                error="404 Page not found"
                message="We can not seem to find the page you are looking for."
              />
            </Route>
          </>
          <>
            <Route path="/admin/list" exact>
              <Redirect to="/admin/subjects" />
            </Route>
            <Route>
              <Error
                error="404 Page not found"
                message="We can not seem to find the page you are looking for."
              />
            </Route>
          </>
        </Switch>
      </div>
    </>
  );
};

export default Admin;
