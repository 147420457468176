import React from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  ModalFooter,
  Input,
} from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import { selectDevotionalList } from '../redux/devotional/selectors';

function AddDevotionalModal({
  toggle,
  basicModal,
  data,
  handleChange,
  onPressUpdate,
  selectingDate,
  handlePost,
  replacingEvent,
}) {
  const { updateLoading } = useSelector(selectDevotionalList);
  return (
    <Modal isOpen={basicModal} toggle={toggle} className="modal-lg">
      <ModalHeader toggle={toggle}>
        {replacingEvent !== null ? (
          <Label style={{ fontSize: '20px' }}>Replace Devotional</Label>
        ) : (
          <Label style={{ fontSize: '20px' }}>Add Devotional</Label>
        )}
      </ModalHeader>
      <ModalBody>
        <div className="mb-2">
          <Label className="form-label" for="title">
            Title:
          </Label>
          <Input
            type="text"
            placeholder="Title"
            value={data.title ?? ''}
            onChange={(e) => handleChange('title', e)}
          />
        </div>
        <div className="mb-2">
          <Label className="form-label" for="description">
            Devotional:
          </Label>
          <Input
            type="textarea"
            placeholder="Devotional"
            rows="7"
            value={data.description}
            onChange={(e) => handleChange('description', e)}
          />
        </div>
        <div className="mb-2">
          <Label className="form-label" for="description">
            Date:
          </Label>

          <Flatpickr
            className="form-control"
            options={{
              dateFormat: 'm/d/Y',
              minDate: new Date().fp_incr(2),
            }}
            value={selectingDate}
            onChange={(e) => handleChange('date', e)}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={handlePost}
          disabled={updateLoading ? true : false}
        >
          {updateLoading
            ? (replacingEvent && 'Replacing...') || 'Adding...'
            : (replacingEvent !== null && 'Replace') || 'Add'}
        </Button>
        <Button color="danger" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default AddDevotionalModal;
