import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools as compose } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import rootReducer from './rootReducer';

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk)

    // window.navigator.userAgent.includes('Chrome')
    //   ? window.devToolsExtension__ && window.devToolsExtension()
    //   : compose

    // window.__REDUX_DEVTOOLS_EXTENSION__
    //   ? window.__REDUX_DEVTOOLS_EXTENSION__()
    //   : (args) => args
  )
);

export default store;
