import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardFooter,
  CardText,
  Container,
  Row,
} from 'reactstrap';

import Header from '../components/Headers/Header';

const Error = ({ error, message }) => {
  return (
    <>
      <Header />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardBody style={{ height: '100%' }}>
                <div className="big_text text">
                  <h1>
                    4<span className="color_blue">0</span>4
                  </h1>
                  <p>
                    <CardText>{message}</CardText>
                  </p>
                  <NavLink to="/admin/list" exact>
                    Go back to home page
                  </NavLink>
                  <NavLink to="/auth/login" exact>
                    Go back to Login page
                  </NavLink>
                </div>
              </CardBody>
              <CardFooter className="py-4"></CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Error;
