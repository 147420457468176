import React from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  ModalFooter,
  Row,
  Input,
} from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import { selectDevotionalList } from '../redux/devotional/selectors';

function AddMoreModal({
  toggleAdd,
  addMoreModal,
  addDevotional,
  addMoreSelectionDate,
  handleAddDevotionalChange,
  handleAddDevotional,
}) {
  const { updateLoading } = useSelector(selectDevotionalList);
  return (
    <Modal isOpen={addMoreModal} toggle={toggleAdd} className="modal-lg">
      <ModalHeader toggle={toggleAdd}>
        <h3>Add Devotional</h3>
      </ModalHeader>
      <ModalBody>
        <div className="mb-2">
          <Label className="form-label" for="title">
            Title:
          </Label>
          <Input
            type="text"
            placeholder="Title"
            value={addDevotional.title}
            onChange={(e) => handleAddDevotionalChange('title', e)}
          />
        </div>
        <div className="mb-2">
          <Label className="form-label" for="description">
            Devotional:
          </Label>
          <Input
            type="textarea"
            placeholder="Devotional"
            rows="7"
            value={addDevotional.description}
            onChange={(e) => handleAddDevotionalChange('description', e)}
          />
        </div>
        <div className="mb-2">
          <Label className="form-label" for="description">
            Date:
          </Label>

          <Flatpickr
            className="form-control"
            options={{
              dateFormat: 'm/d/Y',
              minDate: new Date().fp_incr(2),
            }}
            value={addMoreSelectionDate}
            onChange={(e) => handleAddDevotionalChange('date', e)}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={handleAddDevotional}
          disable={updateLoading ? true : false}
        >
          {updateLoading ? 'Adding...' : 'Add'}
        </Button>
        <Button color="danger" onClick={toggleAdd}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default AddMoreModal;
