import {
  FETCH_DASHBOARD_LIST_FAILURE,
  FETCH_DASHBOARD_LIST_REQUEST,
  FETCH_DASHBOARD_LIST_SUCCESS,
} from './type';

import { getDashboardList } from '../../services/DashboardServices';

export const fetchDashboardListRequest = () => {
  return {
    type: FETCH_DASHBOARD_LIST_REQUEST,
  };
};

export const fetchDashboardListSuccess = (dashboard) => {
  return {
    type: FETCH_DASHBOARD_LIST_SUCCESS,
    payload: dashboard,
  };
};

export const fetchDashboardListFailure = (error) => {
  return {
    type: FETCH_DASHBOARD_LIST_FAILURE,
    payload: error,
  };
};

export const fetchDashboardThunkAction = (params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchDashboardListRequest());
      const { data } = await getDashboardList(params);

      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(fetchDashboardListSuccess(data?.data));
      onSuccess();
    } catch (error) {
      dispatch(fetchDashboardListFailure(error?.response?.data?.message));
      onError(error?.response?.data?.message);
    }
  };
};
