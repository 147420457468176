import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Spinner,
} from 'reactstrap';
import { Table } from 'reactstrap';
import Header from '../components/Headers/Header';
import useViewport from '../hooks/useViewport';
import UnassignedReactTable from './UnassignedReactTable';
import { selectDevotionalList } from '../redux/devotional/selectors';

function UnassignedDevotional() {
  const [error, setError] = React.useState(null);
  const { width } = useViewport();
  const { isLoading, devotionalList, configList, unassignedDevotionalList } =
    useSelector(selectDevotionalList);

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  return (
    <>
      <Header />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="shadow" style={{ minHeight: '50vh' }}>
              <CardHeader
                className={
                  width < 768
                    ? 'border-0'
                    : 'border-0 d-flex justify-content-between'
                }
              >
                <div
                  style={
                    width < 768
                      ? {
                          display: 'flex',
                          justifyContent: 'space-between',
                          marginBottom: '10px',
                        }
                      : {}
                  }
                >
                  <h3 className="mb-0">Unassigned Devotional</h3>
                </div>
              </CardHeader>

              <CardBody className="p-3">
                <UnassignedReactTable />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default UnassignedDevotional;
