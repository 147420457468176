import {
  FETCH_DEVOTIONAL_LIST_FAILURE,
  FETCH_DEVOTIONAL_LIST_REQUEST,
  FETCH_DEVOTIONAL_LIST_SUCCESS,
  FETCH_DEVOTIONAL_CONFIG_REQUEST,
  FETCH_DEVOTIONAL_CONFIG_SUCCESS,
  FETCH_DEVOTIONAL_CONFIG_FAILURE,
  FETCH_UNASSIGNED_DEVOTIONAL_LIST_FAILURE,
  FETCH_UNASSIGNED_DEVOTIONAL_LIST_REQUEST,
  FETCH_UNASSIGNED_DEVOTIONAL_LIST_SUCCESS,
  RESET_IS_UPDATING,
  SET_IS_UPDATING,
  // EXPORT_DEVOTIONAL_CSV,
} from './type';

const initialState = {
  isLoading: false,
  isLoadingConfig: false,
  updateLoading: false,
  error: '',
  devotionalList: [],
  configList: [],
  unassignedDevotionalList: [],
  exportDevotionalData: [],
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DEVOTIONAL_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_DEVOTIONAL_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        devotionalList: action.payload.devotionaList,
        totalCount: action.payload.totalCount,
        currentPage: action.payload.currentPage,
        error: '',
      };

    case FETCH_DEVOTIONAL_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        devotionalList: [],
        error: action.payload,
      };
    case FETCH_DEVOTIONAL_CONFIG_REQUEST:
      return {
        ...state,
        isLoadingConfig: true,
      };
    case FETCH_DEVOTIONAL_CONFIG_SUCCESS:
      return {
        ...state,
        isLoadingConfig: false,
        configList: action.payload,
      };
    case FETCH_DEVOTIONAL_CONFIG_FAILURE:
      return {
        ...state,
        isLoadingConfig: false,
        configList: [],
        error: action.payload,
      };
    case FETCH_UNASSIGNED_DEVOTIONAL_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_UNASSIGNED_DEVOTIONAL_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        unassignedDevotionalList: action.payload.devotionaList,
      };
    case FETCH_UNASSIGNED_DEVOTIONAL_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        unassignedDevotionalList: [],
        error: action.payload,
      };
    case SET_IS_UPDATING:
      return {
        ...state,
        updateLoading: true,
      };
    case RESET_IS_UPDATING:
      return {
        ...state,
        updateLoading: false,
      };

    default:
      return state;
  }
};

export default reducer;
