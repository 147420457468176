import dotenv from 'dotenv';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './redux/store';

import './assets/plugins/nucleo/css/nucleo.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/scss/argon-dashboard-react.scss';
import { Toaster } from 'react-hot-toast';

import App from './App';

dotenv.config();

ReactDOM.render(
  <>
    <Provider store={store}>
      <App />
      <Toaster position="top-center" gutter={10} />
    </Provider>
  </>,
  document.getElementById('root')
);
