import React from 'react';
import { useSelector } from 'react-redux';
import { UncontrolledTooltip } from 'reactstrap';
import { selectDevotionalList } from '../../redux/devotional/selectors';
import moment from 'moment';

function TooltipIcon(props) {
  const {
    target,
    onclickHandler,
    label,
    style,
    disabled = false,
    data,
  } = props;

  const currentDate = moment().format('MM-DD-YYYY');
  const startDate = moment(currentDate, 'MM-DD-YYYY');
  const compareDates = startDate.diff(data.start, 'days') < -1;

  return (
    <>
      <UncontrolledTooltip delay={0} placement="top" target={target}>
        {label}
      </UncontrolledTooltip>
      <div
        style={{
          textOverflow: 'clip',
          overflow: 'hidden',
        }}
      >
        <div
          disabled={disabled}
          id={target}
          onClick={onclickHandler}
          style={{
            background: 'transparent',
            boxShadow: 'none',
            border: 'none',
            transition: 'none',
            opacity: disabled ? 0.4 : 1,
            padding: '0',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <i
            className="fa fa-circle fa-xs"
            style={{
              color: compareDates ? 'green' : 'red',
            }}
          />{' '}
          <b>{label}</b>
        </div>
      </div>
    </>
  );
}

export default TooltipIcon;
