import { toast } from 'react-hot-toast';
import {
  RESET_IS_SIGNING,
  SET_IS_SIGNING,
  SET_LOGGEDIN_USER,
  SET_LOGGEDOUT_USER,
  REFRESH_STATE,
} from './type';

import { getLoggedInUserApi } from '../../services/Authentication';

export const setLoggedInUser = ({ token }) => {
  return {
    type: SET_LOGGEDIN_USER,
    payload: { token },
  };
};

export const setLoggedOutUser = () => {
  return {
    type: SET_LOGGEDOUT_USER,
  };
};

export const setIsSigning = () => {
  return {
    type: SET_IS_SIGNING,
  };
};

export const refreshState = ({ user }) => ({
  type: REFRESH_STATE,
  payload: { user },
});

export const resetIsSigning = () => {
  return {
    type: RESET_IS_SIGNING,
  };
};

export const fetchLoginUserThunkAction = (
  { email, password, role },
  onSuccess
) => {
  return async (dispatch) => {
    try {
      dispatch(setIsSigning());
      const { data } = await getLoggedInUserApi({
        email,
        password,
        role,
      });

      if (data.success !== true) {
        throw new Error(data.message);
      }

      toast.success('Logged in successfully.', { duration: 4000 });
      localStorage.setItem('token', data?.data?.token);
      dispatch(
        setLoggedInUser({
          token: data?.data?.token,
        })
      );
      onSuccess();
      dispatch(resetIsSigning());
    } catch (error) {
      dispatch(resetIsSigning());
      toast.error(error.response?.data?.message || error.message, {
        duration: 4000,
      });
    }
  };
};

export const loggingOutUserThunkAction = (onSuccess) => {
  return async (dispatch) => {
    try {
      localStorage.removeItem('token');
      dispatch(setLoggedOutUser());

      toast.success('Logged out successfully.', { duration: 4000 });
      onSuccess();
    } catch (error) {
      localStorage.removeItem('token');
      toast.error(error?.response?.data?.message, { duration: 4000 });
    }
  };
};
