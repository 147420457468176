/* eslint-disable no-undef */
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  NavLink,
} from 'reactstrap';

import usePasswordShow from '../hooks/usePasswordShow';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const {
    passwordShown: passwordShown1,
    togglePasswordVisiblity: togglePasswordVisiblity1,
    type: type1,
  } = usePasswordShow();
  const {
    passwordShown: passwordShown2,
    togglePasswordVisiblity: togglePasswordVisiblity2,
    type: type2,
  } = usePasswordShow();

  const submitHadler = async (e) => {
    e.preventDefault();

    if (password === '' || confirmPassword === '') {
      toast.error('Password and confirmPassword cannot be empty.');
      return;
    }

    if (password.trim() === '' || confirmPassword.trim() === '') {
      toast.error('White spaces are not allowed.', { duration: 4000 });
      return;
    }

    if (password.includes(' ') || confirmPassword.includes(' ')) {
      toast.error('White spaces are not allowed.', { duration: 4000 });
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Password and confirmPassword doesn't match.", {
        duration: 4000,
      });
      return;
    }

    if (
      !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
        password
      )
    ) {
      toast.error(
        'Password min. 8 char., at least 1 letter, 1 number and 1 special char.'
      );
      return;
    }

    await dispatch(
      await resetPasswordThunkAction(
        { password: password, otp: state.otp, email: state.forgetEmail },
        navigateToHome
      )
    );
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-1">
          <h3 className="text-center mt-3">Reset Password</h3>
          <CardBody className="px-lg-5 py-lg-3">
            <Form role="form" onSubmit={submitHadler}>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type={type1}
                    required
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <InputGroupAddon
                    addonType="append"
                    style={{ cursor: 'pointer', opacity: '0.5' }}
                    onClick={togglePasswordVisiblity1}
                  >
                    <InputGroupText>
                      {passwordShown1 ? (
                        <img
                          alt="hidepassword"
                          src={
                            require('../assets/img/theme/hide-password-icon.png')
                              .default
                          }
                        />
                      ) : (
                        <img
                          alt="showpassword"
                          src={
                            require('../assets/img/theme/show-password-icon.png')
                              .default
                          }
                        />
                      )}
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Confirm Password"
                    type={type2}
                    required
                    name="confirmpassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <InputGroupAddon
                    addonType="append"
                    style={{ cursor: 'pointer', opacity: '0.5' }}
                    onClick={togglePasswordVisiblity2}
                  >
                    <InputGroupText>
                      {passwordShown2 ? (
                        <img
                          alt="hidepassword"
                          src={
                            require('../assets/img/theme/hide-password-icon.png')
                              .default
                          }
                        />
                      ) : (
                        <img
                          alt="showpassword"
                          src={
                            require('../assets/img/theme/show-password-icon.png')
                              .default
                          }
                        />
                      )}
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
              </div>
              <div className="text-center">
                <Button className="my-3" color="primary" type="submit">
                  Submit
                </Button>
                <NavLink to="/auth/login" tag={Link}>
                  <small className="nav-link-inner--text">Sign in?</small>
                </NavLink>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default ResetPassword;
