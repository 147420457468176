import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { Table, Button, CardFooter, Spinner, Label } from 'reactstrap';
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from 'react-table';
import { header } from '../Data/DashboardData';
import SearchComponent from '../components/Search/Search';
import classnames from 'classnames';
import { fetchDevotionalThunkAction } from '../redux/devotional/action';
import { useSelector, useDispatch } from 'react-redux';
import '../App.css';
import NewPagination from '../components/NewPagination/NewPagination';

const ReactTable = () => {
  const [error, setError] = React.useState(null);
  const [search, setSearch] = useState('');
  const [postsPerPage] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [middleSearch, setMiddleSearch] = React.useState('');
  const dispatch = useDispatch();
  const { isLoading, devotionalList, totalCount } = useSelector(
    (state) => state.devotionallist
  );

  useEffect(() => {
    dispatch(
      fetchDevotionalThunkAction(
        {
          search,
          limit: postsPerPage,
          page: currentPage,
        },
        onSuccess,
        onError
      )
    );
  }, [currentPage, search]);

  const onSearchHandler = (keyword) => {
    setCurrentPage(1);
    setSearch(keyword);
  };

  const debouncedSave = React.useCallback(
    debounce((searchText) => {
      setSearch(searchText);
      setCurrentPage(1);
    }, 700),
    [] // will be created only once initially
  );

  React.useEffect(() => {
    debouncedSave(middleSearch);
  }, [middleSearch]);

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  const columns = useMemo(() => header, []);
  const data = useMemo(() => devotionalList, [devotionalList]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination,
    useRowSelect
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,

    prepareRow,
    state,
  } = tableInstance;

  return (
    <>
      <SearchComponent
        search={middleSearch}
        setMiddleSearch={(value) => setMiddleSearch(value)}
        onSearchHandler={onSearchHandler}
      />

      {isLoading ? (
        <Fragment>
          <div className="post_error_loading" style={{ marginLeft: '16%' }}>
            <Spinner />
          </div>
        </Fragment>
      ) : error ? (
        <Fragment>
          <div className="error">{error}</div>
        </Fragment>
      ) : (
        <>
          <Table
            className="align-items-center table-flush"
            responsive
            {...getTableProps()}
          >
            <thead className="thead-light">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps(column)}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            {data.length === 0 ? (
              <tbody>
                <tr>
                  <td
                    colSpan="5"
                    style={{ textAlign: 'center', fontSize: '100%' }}
                  >
                    <Label className="small">No records found.</Label>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody {...getTableBodyProps()}>
                <>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </>
              </tbody>
            )}
          </Table>
          <CardFooter className="py-4">
            <nav aria-label="..." className="post_footer">
              <NewPagination
                totalRecords={totalCount}
                setCurrentPage={setCurrentPage}
                pageLimit={postsPerPage}
                currentPage={currentPage}
                pageNeighbours={1}
              />
            </nav>
          </CardFooter>
        </>
      )}
    </>
  );
};

export default ReactTable;
