import {
  SET_IS_SIGNING,
  SET_LOGGEDIN_USER,
  REFRESH_STATE,
  SET_LOGGEDOUT_USER,
  RESET_IS_SIGNING,
} from './type';

const initialState = {
  loggedInUser: null,
  token: null,
  isLoading: false,
  error: '',
  isSigning: false,
  users: [],
  forgetEmail: '',
  otp: null,
  totalUsers: 0,
  role: '',
  selectedPsychologist: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGGEDIN_USER:
      return {
        ...state,

        token: action.payload.token,

        error: '',
        isSigning: false,
      };
    case SET_LOGGEDOUT_USER:
      return {
        ...state,
        ...initialState,
      };
    case SET_IS_SIGNING:
      return {
        ...state,
        isSigning: true,
      };
    case RESET_IS_SIGNING:
      return {
        ...state,
        isSigning: false,
      };
    case REFRESH_STATE:
      return {
        ...state,
        token: action.payload.token,
        role: action.payload.role,
        loggedInUser: action.payload.user,
      };

    default:
      return state;
  }
};

export default reducer;
