import React, { useState, useEffect, useRef, Fragment } from 'react';
import Header from '../components/Headers/Header';
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Button,
  Row,
  Spinner,
  Col,
  Input,
  Label,
  InputGroup,
} from 'reactstrap';
// import { Tooltip } from 'bootstrap';
import { Tooltip } from 'bootstrap';
import useViewport from '../hooks/useViewport';
import { useDispatch, useSelector } from 'react-redux';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import EditDevotinalModal from './EditDevotionalModal';
import AddDevotionalModal from './AddDevotionalModal';
import AddMoreModal from './AddMoreModal';
import Swal from 'sweetalert2';
// import 'bootstrap/dist/css/bootstrap.min.css';

import moment from 'moment';

import {
  fetchDevotionalThunkAction,
  addDevotionalThunkAction,
  updateDevotionalThunkAction,
  deleteDevotionalThunkAction,
  exportDevotionalDataThunkAction,
  updateDevotionalConfigThunkAction,
  getDevotionalConfigThunkAction,
  getUnassignedDevotionalThunkAction,
} from '../redux/devotional/action';
import { selectDevotionalList } from '../redux/devotional/selectors';

import toast from 'react-hot-toast';
import TooltipIcon from '../components/TooltipIcon/TooltipIcon';
import { CleaningServices } from '@mui/icons-material';

const DEFAULT_DATA = {
  id: '',
  title: '',
  description: '',
  postedOn: '',
};

const ADD_DEVOTIONAL_DATA = {
  id: '',
  title: '',
  description: '',
};

function DevotionalPage() {
  const { width } = useViewport();

  const [basicModal, setBasicModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [addMoreModal, setAddMoreModal] = useState(false);

  const calenderRef = useRef(null);
  const currentDate = moment().format('MM-DD-YYYY');
  const [events, setEvents] = useState([
    { title: "today's event", date: new Date(), description: 'asdasd' },
    { title: "today's event", date: new Date(), description: 'asdasd' },
  ]);

  const [data, setData] = useState(DEFAULT_DATA);
  const [editData, setEditData] = useState(DEFAULT_DATA);
  const [addDevotional, setAddDevotional] = useState(ADD_DEVOTIONAL_DATA);
  const [selectingDate, setSelectingDate] = useState('');
  const [replacingEvent, setReplacingEvent] = useState(null);
  const [editSelectingDate, setEditSelectingDate] = useState('');
  const [addMoreSelectionDate, setAddMoreSelectionDate] = useState('');
  const [error, setError] = React.useState(null);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [postDays, setPostDays] = useState();

  const inputRef = useRef(null);

  const dispatch = useDispatch();
  const { isLoading, isLoadingConfig, devotionalList, configList } =
    useSelector(selectDevotionalList);

  const localDate = moment(devotionalList[0]?.postedOn).format('MM-DD-YYYY');
  const localDatMoment = moment(localDate, 'MM-DD-YYYY');
  const devotionalDiffMoment = moment(
    moment.utc(devotionalList[0]?.postedOn).format('MM-DD-YYYY'),
    'MM-DD-YYYY'
  );

  const result = localDatMoment.diff(devotionalDiffMoment, 'days');

  const setDevotionalEvents = devotionalList?.map((item) => ({
    id: item._id,
    title: item.title,
    description: item.description,
    date: item.postedOn,
    color:
      moment(item.postedOn).format('MM-DD-YYYY') <= currentDate
        ? 'red'
        : 'green',
  }));

  useEffect(() => {
    dispatch(getDevotionalConfigThunkAction(onSuccess, onError));
    dispatch(getUnassignedDevotionalThunkAction(onSuccess, onError));
  }, []);

  useEffect(() => {
    dispatch(
      fetchDevotionalThunkAction(
        {
          startDate,
          endDate,
        },
        onSuccess,
        onError
      )
    );
  }, [startDate, endDate]);

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  const AddConfig = () => {
    dispatch(fetchDevotionalThunkAction(onSuccess, onError));
    dispatch(getDevotionalConfigThunkAction(onSuccess, onError));
    toggleAdd();
  };

  const onAddSuccess = () => {
    dispatch(fetchDevotionalThunkAction(onSuccess, onError));
    dispatch(getUnassignedDevotionalThunkAction(onSuccess, onError));
    toggle();
  };

  const updateSuccess = () => {
    dispatch(fetchDevotionalThunkAction(onSuccess, onError));
    toggleEdit();
  };

  const deleteSuccess = () => {
    dispatch(fetchDevotionalThunkAction(onSuccess, onError));
    dispatch(getDevotionalConfigThunkAction(onSuccess, onError));
    toggleEdit();
  };

  const exportSuccess = () => {
    dispatch(fetchDevotionalThunkAction(onSuccess, onError));
    dispatch(getDevotionalConfigThunkAction(onSuccess, onError));
  };

  const configSuccess = () => {
    dispatch(getDevotionalConfigThunkAction(onSuccess, onError));
  };

  const handleChange = (key, event) => {
    const setDate = moment(new Date(event)).format('MM-DD-YYYY');
    setData((prev) => ({
      ...prev,
      [key]: (key === 'date' && setDate) || event.target.value,
    }));
    if (key === 'date') {
      setSelectingDate(setDate);
    }
  };

  const handleEditChange = (key, event) => {
    const setDate = moment(new Date(event)).format('MM-DD-YYYY');

    setEditData((prev) => ({
      ...prev,
      [key]: (key === 'date' && setDate) || event.target.value,
    }));
    if (key === 'date') {
      setEditSelectingDate(setDate);
    }
  };

  const handleAddDevotionalChange = (key, event) => {
    const setDate = moment(new Date(event)).format('MM-DD-YYYY');
    setAddDevotional((prev) => ({
      ...prev,
      [key]: (key === 'date' && setDate) || event.target.value,
    }));
    if (key === 'date') {
      setAddMoreSelectionDate(setDate);
    }
  };

  const handlePost = async (e) => {
    e.preventDefault();
    if (
      data.title === undefined ||
      data.description === undefined ||
      selectingDate === 'Invalid date'
    ) {
      toast.error('Please enter all the details', { duration: 4000 });
      return;
    }

    if (!replacingEvent) {
      submitDevotional();
      return;
    }
    const popupResponse = await getPopupResponse();

    if (popupResponse.isConfirmed) {
      submitDevotional();
    }
  };

  const submitDevotional = () => {
    const formatSelectingDate = moment(selectingDate, 'MM-DD-YYYY').format(
      'YYYY-MM-DDT00:00:00'
    );

    const setSelectingDateToUtcAdd = moment(formatSelectingDate)
      .add(Math.abs(result), 'days')
      .format('YYYY-MM-DDT00:00:00');

    const setSelectingDateToUtcSub = moment(formatSelectingDate)
      .subtract(Math.abs(result), 'days')
      .format('YYYY-MM-DDT00:00:00');

    dispatch(
      addDevotionalThunkAction(
        {
          title: data.title,
          description: data.description,
          postedOn:
            result < 0 ? setSelectingDateToUtcAdd : setSelectingDateToUtcSub,
        },
        onAddSuccess,
        onError
      )
    );
  };

  const getPopupResponse = async () =>
    await Swal.fire({
      title: 'Are you sure want to replace?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      background:
        localStorage.getItem('skin') === '"' + 'dark' + '"'
          ? '#161D31'
          : '#F8F8F8',
      showCancelButton: true,
      confirmButtonText: 'Yes, replace it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ms-1',
      },
      buttonsStyling: false,
    });

  const listOfDates = setDevotionalEvents.map((item) =>
    moment(item.date).format('MM-DD-YYYY')
  );

  const handleAddDevotional = async (e) => {
    e.preventDefault();
    if (
      addDevotional.title === undefined ||
      addDevotional.description === undefined
    ) {
      toast.error('Please enter all the details.', { duration: 4000 });
      return;
    }

    console.log(addMoreSelectionDate);
    // local date
    const formatUnassignedEditingDate = addMoreSelectionDate
      ? moment(addMoreSelectionDate, 'MM-DD-YYYY').format('YYYY-MM-DDT00:00:00')
      : null;
    // convert local date to utc
    const setSelectingDateToUtcAdd = addMoreSelectionDate
      ? moment(formatUnassignedEditingDate)
          .add(Math.abs(result), 'days')
          .format('YYYY-MM-DDT00:00:00')
      : null;

    const setSelectingDateToUtcSub = addMoreSelectionDate
      ? moment(formatUnassignedEditingDate)
          .subtract(Math.abs(result), 'days')
          .format('YYYY-MM-DDT00:00:00')
      : null;

    // filter the selected date in listDates
    const filteredListOfDates = listOfDates.filter(
      (date) => date === addMoreSelectionDate
    );

    if (addMoreSelectionDate === filteredListOfDates[0]) {
      await getPopupResponse();
      return;
    } else {
      dispatch(
        addDevotionalThunkAction(
          {
            title: addDevotional.title,
            description: addDevotional.description,
            postedOn:
              result < 0 ? setSelectingDateToUtcAdd : setSelectingDateToUtcSub,
          },
          AddConfig,
          onError
        )
      );
      setAddDevotional('');
    }

    setAddDevotional('');
  };

  const handleEdit = async () => {
    if (
      editData.title.trim() === '' ||
      editData.description.trim() === '' ||
      editSelectingDate === 'Invalid date'
    ) {
      toast.error('Please enter all the details.', { duration: 4000 });
      return;
    }
    // local date format
    const formatEditingDate = moment(editSelectingDate, 'MM-DD-YYYY').format(
      'YYYY-MM-DDT00:00:00'
    );

    // convert to UTC date format
    const setSetectingDateToUtcAdd = moment(formatEditingDate)
      .add(Math.abs(result), 'days')
      .format('YYYY-MM-DDT00:00:00');

    const setSetectingDateToUtcSub = moment(formatEditingDate)
      .subtract(Math.abs(result), 'days')
      .format('YYYY-MM-DDT00:00:00');

    // pass inside the thunk action
    dispatch(
      updateDevotionalThunkAction(
        editData.id,
        {
          title: editData.title,
          description: editData.description,
          postedOn:
            result < 0 ? setSetectingDateToUtcAdd : setSetectingDateToUtcSub,
        },
        updateSuccess,
        onError
      )
    );
  };

  const handleConfig = () => {
    if (postDays !== undefined) {
      dispatch(
        updateDevotionalConfigThunkAction(+postDays, configSuccess, onError)
      );
    }
  };

  const handleDelete = async () => {
    const popupResponse = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      background:
        localStorage.getItem('skin') === '"' + 'dark' + '"'
          ? '#161D31'
          : '#F8F8F8',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ms-1',
      },
      buttonsStyling: false,
    });
    if (popupResponse.isConfirmed) {
      dispatch(
        deleteDevotionalThunkAction(editData.id, deleteSuccess, onError)
      );
    }
  };

  const handleFileClick = (event) => {
    inputRef.current.click();
  };

  const handleExport = () => {
    handleFileClick();
  };

  const handleFileChange = (event) => {
    // setFile(event.target.files[0]);
    const file = event.target.files[0];
    const configListDate = configList?.lastAssignedDate;
    const formatDate = moment(configListDate).format('YYYY-MM-DDTHH:mm:ss');
    const dateUtc = moment(formatDate).utc().format('YYYY-MM-DDTHH:mm:ss');
    if (file) {
      const formData = new FormData();
      formData.append('csv', file);
      formData.append('lastAssignedDate', dateUtc);
      dispatch(
        exportDevotionalDataThunkAction(formData, exportSuccess, onError)
      );
    }
  };

  const toggle = () => {
    if (basicModal) {
      setReplacingEvent(null);
    }
    setBasicModal(!basicModal);
    setData('');
  };

  const toggleEdit = () => {
    setEditModal(!editModal);
  };

  const toggleAdd = () => {
    setAddMoreModal(!addMoreModal);
    setAddMoreSelectionDate('');
    setAddDevotional('');
  };

  const EditToggle = (event) => {
    const setDate = moment(event.start).format('MM-DD-YYYY');
    setEditSelectingDate(setDate);
    setEditData({
      id: event.id,
      title: event.title,
      description: event.extendedProps.description,
      postedOn: setDate,
    });

    // closeTooltip();
    setEditModal(!editModal);
    toggleEdit();
  };

  function getFormatedDate(d) {
    var b = new Date();
    b.setMonth(parseInt(d.substr(0, 2)) - 1);
    b.setDate(parseInt(d.substr(3, 2)));
    b.setFullYear(parseInt(d.substr(6, 4)));
    return b;
  }

  const handleSelect = (arg) => {
    let date = moment(arg.startStr).format('MM-DD-YYYY');
    date = getFormatedDate(date);
    const selectDate = moment(date).format('YYYY-MM-DDTHH:mm:ss');
    const selectedDateInStdFormat = moment(date).format('yyyy-MM-DD');

    let selectedEvent = null;

    setDevotionalEvents.map((item) => {
      const eventDate = moment(item.date).format('yyyy-MM-DD');

      if (eventDate === selectedDateInStdFormat) {
        selectedEvent = item;
      }
    });
    if (selectedEvent !== null) {
      setReplacingEvent(selectedEvent);
      const utcDate = moment(selectDate).utc().format('YYYY-MM-DDTHH:mm:ss');
      setSelectingDate(date);
      setData((prev) => ({ ...prev, postedOn: utcDate }));
    } else {
      setReplacingEvent(null);
      const utcDate = moment(selectDate).utc().format('YYYY-MM-DDTHH:mm:ss');
      setSelectingDate(date);
      setData((prev) => ({ ...prev, postedOn: utcDate }));
    }
    toggle();
  };

  const onPressUpdate = () => {
    let newEventData = [...events, data];
    setEvents([...newEventData]);
    setData(DEFAULT_DATA);
    setSelectingDate('');
    toggle();
  };

  const handleSelectAllow = (arg) => {
    const currentDate = moment().format('YYYY-MM-DD');
    const startDate = moment(currentDate);
    return startDate.diff(arg.startStr, 'days') < -1;
  };

  // compare the current date with the past date

  const handleKeyDown = (event) => {
    if (event.key === '.') {
      event.preventDefault();
    }
  };

  return (
    <>
      <Header />
      {isLoading || isLoadingConfig ? (
        <Fragment>
          <div className="post_error_loading">
            <Spinner />
          </div>
        </Fragment>
      ) : error ? (
        <Fragment>
          <div className="error">{error}</div>
        </Fragment>
      ) : (
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card className="shadow" style={{ minHeight: '50vh' }}>
                <CardHeader
                  className={
                    width < 768
                      ? 'border-0'
                      : 'border-0 d-flex justify-content-between'
                  }
                >
                  <div
                    style={
                      width < 768
                        ? {
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: '10px',
                          }
                        : {}
                    }
                  >
                    <h3 className="mb-0">Devotional</h3>
                  </div>

                  <div></div>

                  <div>
                    <input
                      style={{ display: 'none' }}
                      ref={inputRef}
                      type="file"
                      accept={'.csv'}
                      onChange={handleFileChange}
                    />
                    <Button outline color="primary" onClick={handleExport}>
                      <i className="fa fa-upload" /> Batch Upload
                    </Button>
                    <Button color="primary" onClick={toggleAdd}>
                      Add Devotional
                    </Button>
                  </div>
                </CardHeader>
                <CardBody>
                  <React.Fragment>
                    <div
                      style={{
                        width: '100%',
                        height: 'auto',
                        textAlign: 'center',
                        margin: 'auto',
                      }}
                    >
                      <Row className="justify-content-center">
                        <Col md="10">
                          <FullCalendar
                            plugins={[
                              dayGridPlugin,
                              timeGridPlugin,
                              interactionPlugin,
                              listPlugin,
                            ]}
                            headerToolbar={{
                              left: 'prev',
                              center: 'title',
                              right: 'next',
                            }}
                            ref={calenderRef}
                            initialView="dayGridMonth"
                            editable={true}
                            selectable={true}
                            selectMirror={true}
                            dayMaxEvents={true}
                            aspectRatio={2}
                            displayEventTime={false}
                            selectAllow={handleSelectAllow}
                            select={handleSelect}
                            events={setDevotionalEvents}
                            eventClick={(arg) => EditToggle(arg.event)}
                            eventContent={(arg) => {
                              return (
                                <TooltipIcon
                                  target={'event' + arg.event.id}
                                  label={arg.event.title}
                                  onclickHandler={() => EditToggle(arg.event)}
                                  data={arg.event}
                                />
                              );
                            }}
                          />
                          <AddDevotionalModal
                            toggle={toggle}
                            basicModal={basicModal}
                            data={data}
                            handleChange={handleChange}
                            onPressUpdate={onPressUpdate}
                            selectingDate={selectingDate}
                            handlePost={handlePost}
                            replacingEvent={replacingEvent}
                          />
                          <EditDevotinalModal
                            setEditModal={setEditModal}
                            editModal={editModal}
                            handleEditChange={handleEditChange}
                            setEditData={setEditData}
                            editData={editData}
                            handleEdit={handleEdit}
                            toggleEdit={toggleEdit}
                            events={events}
                            editSelectingDate={editSelectingDate}
                            handleDelete={handleDelete}
                          />
                          <AddMoreModal
                            toggleAdd={toggleAdd}
                            addMoreModal={addMoreModal}
                            handleAddDevotionalChange={
                              handleAddDevotionalChange
                            }
                            addMoreSelectionDate={addMoreSelectionDate}
                            addDevotional={addDevotional}
                            handleAddDevotional={handleAddDevotional}
                          />
                        </Col>
                      </Row>
                    </div>

                    <div className="mt-5 mb-5">
                      <div
                        style={{
                          height: '1px',
                          border: '0',
                          borderTop: '1px solid #ccc',
                          padding: '5px',
                          marginBottom: '5px',
                        }}
                      >
                        <Row mt="5">
                          <Col lg="2">
                            <Label className="ml-3">
                              The number of days a user can visit devotionals:{' '}
                            </Label>{' '}
                          </Col>
                          <Col lg="2">
                            <InputGroup>
                              <Input
                                type="number"
                                defaultValue={
                                  configList !== undefined &&
                                  configList?.pastDays
                                }
                                pattern="[0-9]"
                                value={postDays}
                                onKeyDown={handleKeyDown}
                                onChange={(e) => setPostDays(e.target.value)}
                              />
                            </InputGroup>
                          </Col>
                          <Col lg="4">
                            <Button
                              className="mb-5"
                              color="primary"
                              onClick={handleConfig}
                            >
                              Update
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </div>

                    <div className="mt-5 set-instruction">
                      <Row mt="5">
                        <Col lg="8">
                          <Label className="ml-4 mt-3">
                            <b>Instructions:</b>
                          </Label>{' '}
                          <ol>
                            <li>
                              To update devotional, click on title of devotional
                            </li>
                            <li>
                              To replace devotional, click anywhere in date box
                            </li>
                            <li>
                              To add devotional, click anywhere in blank date
                              box
                            </li>
                            <li>
                              {`To upload one or multiple devotional automatically
                              to the next open available date, click 'Batch
                              Upload' button and upload .csv file Format of .csv
                              file is title , description`}{' '}
                            </li>
                            <li>{`To add devotional manually in particular date, click 'Add Devotional' button`}</li>
                          </ol>
                        </Col>
                        <Col></Col>
                      </Row>
                    </div>
                  </React.Fragment>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      )}
    </>
  );
}

export default DevotionalPage;
