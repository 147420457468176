import AxiosInstance from './AxiosInstance';

export const getDevotional = (prms) =>
  AxiosInstance.get(`devotional/get-devotional-list`, {
    params: {
      page: prms?.page || 1,
      limit: prms?.limit || 0,
      search: prms?.search || '',
      startDate: prms?.startDate,
      endDate: prms?.endDate,
    },
  });

export const addDevotional = (userData) =>
  AxiosInstance.post('devotional/create-post', userData);

export const editDevotional = (id, userData) => {
  return AxiosInstance.patch(`devotional/update-post/${id}`, userData);
};

export const deleteDevotional = (id) => {
  return AxiosInstance.delete(`devotional/delete-post/${id}`);
};

export const exportDevotionalData = (file) => {
  return AxiosInstance.post('devotional/upload-csv', file);
};

export const updateConfig = (pastDays) => {
  return AxiosInstance.patch(`devotional/update-config`, { pastDays });
};

export const getConfig = () => {
  return AxiosInstance.get(`devotional/config`);
};

export const getCurrentDevotional = ({ startDate, endDate }) => {
  return AxiosInstance.get(`devotional/get-current-devotional`, {
    startDate,
    endDate,
  });
};

export const getUnassignedDevotional = () => {
  return AxiosInstance.get(`devotional/unassigned/devotional-list`);
};
