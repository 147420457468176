import ViewDetailsModal from '../pages/ViewDetailsModal';
import { format } from 'date-fns';

export const sample = [];

export const header = [
  {
    Header: 'Title',
    accessor: 'title',
  },
  {
    Header: 'Published Date',
    accessor: 'postedOn',
    Cell: (props) => {
      const custom_date = format(new Date(props.value), 'MM/dd/yyyy');
      return <span>{custom_date}</span>;
    },
  },
  {
    Header: 'Total Views',
    accessor: 'totalViewsCount',
  },
  {
    Header: 'Unique Views',
    accessor: 'uniqueViewsCount',
  },

  {
    Header: 'Devotional Details',
    accessor: 'action',
    Cell: (props) => <ViewDetailsModal modalData={props} />,
  },
];
