import toast from 'react-hot-toast';
import Swal from 'sweetalert2';

import {
  FETCH_DEVOTIONAL_LIST_FAILURE,
  FETCH_DEVOTIONAL_LIST_REQUEST,
  FETCH_DEVOTIONAL_LIST_SUCCESS,
  SET_IS_UPDATING,
  RESET_IS_UPDATING,
  FETCH_DEVOTIONAL_CONFIG_FAILURE,
  FETCH_DEVOTIONAL_CONFIG_REQUEST,
  FETCH_DEVOTIONAL_CONFIG_SUCCESS,
  FETCH_UNASSIGNED_DEVOTIONAL_LIST_FAILURE,
  FETCH_UNASSIGNED_DEVOTIONAL_LIST_REQUEST,
  FETCH_UNASSIGNED_DEVOTIONAL_LIST_SUCCESS,
} from './type';

import {
  getDevotional,
  addDevotional,
  editDevotional,
  deleteDevotional,
  exportDevotionalData,
  updateConfig,
  getConfig,
  getUnassignedDevotional,
} from '../../services/DevotionalServices';

export const fetchDevotionalListRequest = () => {
  return {
    type: FETCH_DEVOTIONAL_LIST_REQUEST,
  };
};

export const fetchDevotionalListSuccess = (devotionals) => {
  return {
    type: FETCH_DEVOTIONAL_LIST_SUCCESS,
    payload: devotionals,
  };
};

export const fetchDevotionalListFailure = (error) => {
  return {
    type: FETCH_DEVOTIONAL_LIST_FAILURE,
    payload: error,
  };
};

export const fetchConfigDevotionalListRequest = () => {
  return {
    type: FETCH_DEVOTIONAL_CONFIG_REQUEST,
  };
};

export const fetchConfigDevotionalListSuccess = (configList) => {
  return {
    type: FETCH_DEVOTIONAL_CONFIG_SUCCESS,
    payload: configList,
  };
};

export const fetchConfigDevotionalListFailure = () => {
  return {
    type: FETCH_DEVOTIONAL_CONFIG_FAILURE,
  };
};

export const fetchUnassignedDevotionalListRequest = () => {
  return {
    type: FETCH_UNASSIGNED_DEVOTIONAL_LIST_REQUEST,
  };
};

export const fetchUnassignedDevotionalListSuccess = (unassignedDevotionals) => {
  return {
    type: FETCH_UNASSIGNED_DEVOTIONAL_LIST_SUCCESS,
    payload: unassignedDevotionals,
  };
};

export const fetchUnassignedDevotionalListFailure = () => {
  return {
    type: FETCH_UNASSIGNED_DEVOTIONAL_LIST_FAILURE,
  };
};

export const setUpdateLoading = () => {
  return {
    type: SET_IS_UPDATING,
  };
};

export const resetUpdateLoading = () => {
  return {
    type: RESET_IS_UPDATING,
  };
};

export const fetchDevotionalThunkAction = (params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchDevotionalListRequest());
      const { data } = await getDevotional(params);

      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(fetchDevotionalListSuccess(data?.data));
      onSuccess();
    } catch (error) {
      dispatch(fetchDevotionalListFailure(error?.response?.data?.message));
      onError(error?.response?.data?.message);
    }
  };
};

export const addDevotionalThunkAction = (userData, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(setUpdateLoading());
      const { data } = await addDevotional(userData);

      if (data.success !== true) {
        throw new Error(data.message);
      }
      onSuccess();
      dispatch(resetUpdateLoading());
      toast.success(data?.message, { duration: 4000 });
    } catch (error) {
      toast.error(error?.response?.data?.message, { duration: 4000 });
      onError(error?.response?.data?.message);
    }
  };
};

export const updateDevotionalThunkAction = (
  id,
  userData,
  onSuccess,
  onError
) => {
  return async (dispatch) => {
    try {
      dispatch(setUpdateLoading());
      const { data } = await editDevotional(id, userData);
      if (data.success !== true) {
        throw new Error(data.message);
      }
      onSuccess();
      dispatch(resetUpdateLoading());

      toast.success(data?.message, { duration: 4000 });
    } catch (error) {
      const popupResponse = await Swal.fire({
        title: 'Devotional already exist on this date.',
        icon: 'warning',
        background:
          localStorage.getItem('skin') === '"' + 'dark' + '"'
            ? '#161D31'
            : '#F8F8F8',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ms-1',
        },
        buttonsStyling: false,
      });
      if (popupResponse.isConfirmed) {
        onError(error?.response?.data?.message);
      }

      dispatch(resetUpdateLoading());
    }
  };
};

export const deleteDevotionalThunkAction = (id, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      const { data } = await deleteDevotional(id);

      if (data.success !== true) {
        throw new Error(data.message);
      }
      onSuccess();
      toast.success(data.message, { duration: 4000 });
    } catch (error) {
      toast.error(error?.response?.data?.message, { duration: 4000 });
      onError(error?.response?.data?.message);
    }
  };
};

export const exportDevotionalDataThunkAction = (
  formData,

  onSuccess,
  onError
) => {
  return async (dispatch) => {
    try {
      dispatch(setUpdateLoading());
      const { data } = await exportDevotionalData(formData);

      if (data.success !== true) {
        throw new Error(data.message);
      } else {
        onSuccess();
        dispatch(resetUpdateLoading());
        toast.success(data.message, { duration: 4000 });
      }
    } catch (error) {
      toast.error(error?.message, { duration: 4000 });
      onError(error?.response?.data?.message);
    }
  };
};

export const updateDevotionalConfigThunkAction = (
  postDays,
  onSuccess,
  onError
) => {
  return async (dispatch) => {
    try {
      dispatch(setUpdateLoading());
      const { data } = await updateConfig(postDays);

      if (data.success !== true) {
        throw new Error(data.message);
      }
      onSuccess();
      dispatch(resetUpdateLoading());
      toast.success(data.message, { duration: 4000 });
    } catch (error) {
      toast.error(error?.response?.data?.message, { duration: 4000 });
      onError(error?.response?.data?.message);
    }
  };
};

export const getDevotionalConfigThunkAction = (onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchConfigDevotionalListRequest());
      const { data } = await getConfig();
      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(fetchConfigDevotionalListSuccess(data?.data));
      onSuccess();
    } catch (error) {
      dispatch(
        fetchConfigDevotionalListFailure(error?.response?.data?.message)
      );
      onError(error?.response?.data?.message);
    }
  };
};

export const getUnassignedDevotionalThunkAction = (onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchUnassignedDevotionalListRequest());
      const { data } = await getUnassignedDevotional();
      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(fetchUnassignedDevotionalListSuccess(data?.data));
      onSuccess();
    } catch (error) {
      dispatch(
        fetchUnassignedDevotionalListFailure(error?.response?.data?.message)
      );
      onError(error?.response?.data?.message);
    }
  };
};
