import React from 'react';
import { Search } from 'react-feather';
import { InputGroup, Input, InputGroupText, Form, FormGroup } from 'reactstrap';

const SearchComponent = ({ onSearchHandler, search, setMiddleSearch }) => {
  return (
    <Form
      role="form"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <FormGroup className="d-flex float-right mt-4">
        <InputGroup className="input-group-alternative">
          <InputGroupText>
            <i className="ni ni-zoom-split-in" />
          </InputGroupText>
          <Input
            placeholder="Search"
            type="text"
            value={search}
            onChange={(e) => setMiddleSearch(e.target.value)}
          />
        </InputGroup>
      </FormGroup>
    </Form>
  );
};

export default SearchComponent;
