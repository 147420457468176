import { combineReducers } from 'redux';
import userReducer from './user/reducer';
import devotionalReducer from './devotional/reducer';
import dashboardReducer from './dashboard/reducer';

const rootReducer = combineReducers({
  user: userReducer,
  devotionallist: devotionalReducer,
  dashboardlist: dashboardReducer,
});

export default rootReducer;
