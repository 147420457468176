import moment from 'moment';
import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Label, Row, Col } from 'reactstrap';
import '../App.css';

function ViewDetailsModal({ modalData }) {
  const [viewModal, setViewModal] = useState(false);
  const ViewDetailsToggle = () => {
    setViewModal(!viewModal);
  };

  return (
    <>
      <div className="vertically-centered-modal">
        <i
          className="fa fa-eye cursor-pointer"
          style={{ color: '#5A6B5D', alignItems: 'center', cursor: 'pointer' }}
          onClick={ViewDetailsToggle}
        />{' '}
        <Modal
          scrollable
          isOpen={viewModal}
          toggle={ViewDetailsToggle}
          className="modal-dialog-centered"
          size="xl"
        >
          <ModalHeader toggle={ViewDetailsToggle}>
            <Label style={{ fontSize: '20px' }}>Devotional Details</Label>
          </ModalHeader>

          <ModalBody>
            <Row>
              <div
                className="col-lg-8 col-12"
                style={{
                  overflowY: 'auto',
                  height: '500px',
                }}
              >
                <div className="mb-2">
                  <Label className="form-label" for="title">
                    <b>Title:</b>
                  </Label>{' '}
                  <Label>
                    {modalData.row.original.title === 0 ? (
                      <Label className="small">No title are available</Label>
                    ) : (
                      modalData.row.original.title
                    )}
                  </Label>
                </div>
                <div className="mb-2">
                  <Label className="form-label mt-2" for="description">
                    <b>Published Date: </b>
                  </Label>

                  <Label style={{ textIndent: '3px' }}>
                    {moment(modalData.row.original.postedOn).format(
                      'MM/DD/YYYY'
                    ) === 0 ? (
                      <Label className="small">
                        No description are available
                      </Label>
                    ) : (
                      moment(modalData.row.original.postedOn).format(
                        'MM/DD/YYYY'
                      )
                    )}
                  </Label>
                </div>
                <div className="mb-2">
                  <Label className="form-label mt-2" for="description">
                    <b>Devotional:</b>
                  </Label>

                  <Label>
                    {modalData.row.original.description === 0 ? (
                      <Label className="small">
                        No description are available
                      </Label>
                    ) : (
                      modalData.row.original.description
                    )}
                  </Label>
                </div>
              </div>

              <div
                className="col-lg-4 col-12"
                style={{ overflowY: 'auto', height: '500px' }}
              >
                <div className="popup-border-left">
                  <div className="mb-2">
                    <Label className="form-label" for="title">
                      <b>Messages:</b>
                    </Label>{' '}
                  </div>

                  {modalData.row.original.messages.length === 0 ? (
                    <Label className="small">No messages.</Label>
                  ) : (
                    <>
                      {modalData.row.original.messages?.map((item) => (
                        <>
                          <div>
                            <div>
                              <span>
                                <i className="fas fa-user" />
                                <Label
                                  className="form-label ml-2"
                                  style={{ wordWrap: 'break-word' }}
                                >
                                  <b>{item.userName}</b>
                                </Label>
                                <br />
                              </span>
                              <Label>{item.message}</Label>
                              <br />
                              <Label className="small">
                                {moment(item.createdAt).format('lll')}
                              </Label>
                              <br />
                            </div>
                            <hr />
                          </div>
                        </>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
}

export default ViewDetailsModal;
