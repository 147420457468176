import { Container } from 'reactstrap';
import useViewport from '../../hooks/useViewport';

const Header = () => {
  const { width } = useViewport();

  return (
    <>
      <div className="header bg-gradient-main-color pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div
            className="position-absolute header-body text-light font-weight-bold"
            style={{
              top: '7%',
              right: width < 1326 ? '15%' : '10%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
            }}
          ></div>
        </Container>
      </div>
    </>
  );
};

export default Header;
