export const FETCH_DEVOTIONAL_LIST_REQUEST = 'FETCH_DEVOTIONAL_LIST_REQUEST';
export const FETCH_DEVOTIONAL_LIST_SUCCESS = 'FETCH_DEVOTIONAL_LIST_SUCCESS';
export const FETCH_DEVOTIONAL_LIST_FAILURE = 'FETCH_DEVOTIONAL_LIST_FAILURE';

export const FETCH_DEVOTIONAL_CONFIG_REQUEST =
  'FETCH_DEVOTIONAL_CONFIG_REQUEST';
export const FETCH_DEVOTIONAL_CONFIG_SUCCESS =
  'FETCH_DEVOTIONAL_CONFIG_SUCCESS';
export const FETCH_DEVOTIONAL_CONFIG_FAILURE =
  'FETCH_DEVOTIONAL_CONFIG_FAILURE';

export const FETCH_UNASSIGNED_DEVOTIONAL_LIST_REQUEST =
  'FETCH_UNASSIGNED_DEVOTIONAL_LIST_REQUEST';
export const FETCH_UNASSIGNED_DEVOTIONAL_LIST_SUCCESS =
  'FETCH_UNASSIGNED_DEVOTIONAL_LIST_SUCCESS';
export const FETCH_UNASSIGNED_DEVOTIONAL_LIST_FAILURE =
  'FETCH_UNASSIGNED_DEVOTIONAL_LIST_FAILURE';

export const SET_IS_UPDATING = 'SET_IS_UPDATING';
export const RESET_IS_UPDATING = 'RESET_IS_UPDATING';
